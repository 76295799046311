(function ($) {
    "use strict";

    // --------------------------------------------------
    // owlCarousel
    // --------------------------------------------------

    /* latest-news-items */
    $(".latest-news-items-3").owlCarousel({
        items: 2,
        itemsDesktop: [1199, 2],
        itemsDesktopSmall: [979, 2],
        itemsTablet: [768, 1],
        itemsTabletSmall: false,
        itemsMobile: [479, 1],
        pagination: false,
        autoPlay: false,
        slideSpeed: 300,
    });

    // Custom Navigation owlCarousel - ".latest-news-items"
    $(".latest-next").on("click", function () {
        $(this).parent().parent().parent().find('.latest-news-items-3').trigger('owl.next');
    });
    $(".latest-prev").on("click", function () {
        $(this).parent().parent().parent().find('.latest-news-items-3').trigger('owl.prev');
    });

    /*Clients Logo */
    $("#client-logo").owlCarousel({
        items: 2,
        itemsCustom: false,
        itemsDesktop: [1199, 5],
        itemsDesktopSmall: [979, 4],
        itemsTablet: [768, 2],
        itemsTabletSmall: [768, 3],
        itemsMobile: [479, 1],
        singleItem: false,
        navigation: false,
        pagination: true,
        autoPlay: false,
        slideSpeed: 400,
    });

    $(document).ready(function () {

        $('.post-entry-more-show-btn').click(function (e) {
            e.preventDefault();
            var btn = $(this);
            var btn_icon = btn.find('i.fa');
            var more_elem = $(this).parents('.post-entry').find(' .post-entry-more');
            if (more_elem.hasClass('visible')) {
                more_elem.removeClass('visible');
                more_elem.slideUp();
                btn_icon.removeClass('fa-minus-square').addClass('fa-plus-square');
            }
            else {
                more_elem.addClass('visible');
                more_elem.slideDown();
                btn_icon.removeClass('fa-plus-square').addClass('fa-minus-square');
            }
        });

        $('[data-toggle="tooltip"]').tooltip();

    });

})(jQuery); 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 





	